import {
    IIconProps,
    PrimaryButton,
    Link,
    Image,
    ImageFit,
    DefaultButton
} from '@fluentui/react';
import { Nsat, TemplateNames } from 'Shared/constants';
import {
    IconLoader,
    CopyToClipboard
} from 'Shared/Controls';
import { DateUtils } from 'Shared/Utils';
import { DemoRequest } from 'Services';
import styles from '../demorequests.module.scss';
import { StatusIcon } from '../status-icon/StatusIcon';
import { messages } from '../../demorequests.settings';
import { DemoType } from 'Services/Enums';

const iconProps: IIconProps = { iconName: 'Articles' };

interface DataListProps {
    demoRequestItem: DemoRequest;
    isEnvironmentLinkActive: (demoRequestItem: DemoRequest) => boolean;
    onDemoAssetClick: (demoRequest: DemoRequest) => void;
    onDemoScriptClick: (demoRequest: DemoRequest) => void;
    onConfigStepsClick: (demoRequest: DemoRequest) => void;
    demoType?: number;
}

export const DataList = (props: DataListProps) => {

    const { demoType } = props;
    const stylingClassName = getCardStylingByDemoTypeClassName(demoType);

    return <div className={styles.requestsContainer} key={props.demoRequestItem.EnvironmentName}>
        <div className={`${styles.imageContainer} ${stylingClassName}`}>
            <Image
                imageFit={ImageFit.cover}
                src={props.demoRequestItem.TemplateIconUrl}
                alt={props.demoRequestItem.TemplateName}
            />
        </div>
        <div className={`${styles.copyContainer} ${stylingClassName}`}>
            <div className={styles.innerText}>
                <h3 className={styles.textMedium} title={props.demoRequestItem.EnvironmentName}>
                    {props.demoRequestItem.EnvironmentName}</h3>
            </div>
            <div className={`${styles.envLinkContainer}`}>
                <IconLoader iconName="link" className={styles.grayedIcon} ariaLabel={messages.ariaLabelEnvLinkIcon} />
                {props.demoRequestItem.SecondaryEnvironmentUrl && props.demoRequestItem.TemplateName === TemplateNames.FnO ?
                    <div>
                        <div className={styles.demoRequestLabel}>
                            <div className={styles.turncateText}>
                                {props.demoRequestItem.SecondaryEnvironmentUrl && props.demoRequestItem.SecondaryEnvironmentUrl.length &&
                                    <Link
                                        id={`lnkEnvironment-${props.demoRequestItem.TemplateName}`}
                                        href={props.demoRequestItem.SecondaryEnvironmentUrl}
                                        target="_blank"
                                        title={props.demoRequestItem.SecondaryEnvironmentUrl}
                                        disabled={!props.isEnvironmentLinkActive(props.demoRequestItem)}>
                                        {props.demoRequestItem.SecondaryEnvironmentUrl}
                                    </Link>
                                }
                                {(!props.demoRequestItem?.SecondaryEnvironmentUrl || props.demoRequestItem?.SecondaryEnvironmentUrl.length === 0) &&
                                    <span className={styles.textSmall}>{messages.placeholderEnvironmentLink}</span>}
                            </div>
                        </div>
                        {props.demoRequestItem.SecondaryEnvironmentUrl && props.demoRequestItem.SecondaryEnvironmentUrl.length && props.isEnvironmentLinkActive(props.demoRequestItem) &&
                            <CopyToClipboard
                                textToCopy={props.demoRequestItem.SecondaryEnvironmentUrl}
                                textType={messages.environmentUrl}
                                dataAttributes={Nsat.EventTriggerAttribute}
                            />
                        }
                    </div>
                    :
                    <div>
                        <div className={styles.demoRequestLabel}>
                            <div className={styles.turncateText}>
                                {props.demoRequestItem.EnvironmentUrl && props.demoRequestItem.EnvironmentUrl.length &&
                                    <Link
                                        id={`lnkEnvironment-${props.demoRequestItem.TemplateName}`}
                                        href={props.demoRequestItem.EnvironmentUrl}
                                        target="_blank"
                                        title={props.demoRequestItem.EnvironmentUrl}
                                        disabled={!props.isEnvironmentLinkActive(props.demoRequestItem)}>
                                        {props.demoRequestItem.EnvironmentUrl}
                                    </Link>
                                }
                                {(!props.demoRequestItem?.EnvironmentUrl || props.demoRequestItem?.EnvironmentUrl.length === 0) &&
                                    <span className={styles.textSmall}>{messages.placeholderEnvironmentLink}</span>}
                            </div>
                        </div>
                        {props.demoRequestItem.EnvironmentUrl && props.demoRequestItem.EnvironmentUrl.length && props.isEnvironmentLinkActive(props.demoRequestItem) &&
                            <CopyToClipboard
                                textToCopy={props.demoRequestItem.EnvironmentUrl}
                                textType={messages.environmentUrl}
                                dataAttributes={Nsat.EventTriggerAttribute}
                            />
                        }
                    </div>
                }
            </div>
            <div className={styles.metaInfo}>
                <div className={styles.demoRequestLabel}>
                    <span>Demo Admin</span>
                    <div className={styles.iconContentContainer}>
                        <IconLoader iconName="Contact" className={styles.grayedIcon} ariaLabel={messages.ariaLabelDemoUserIcon} />
                        <span className={`${styles.textSmall} ${styles.turncateText}`}
                            title={props.demoRequestItem.DemoUserAccount}>{props.demoRequestItem.DemoUserAccount}</span>
                    </div>
                </div>
                <div>
                    <span>Created on</span>
                    <div className={styles.iconContentContainer}>
                        <IconLoader iconName="Calendar" className={styles.grayedIcon} ariaLabel={messages.ariaLabelDemoRequestDateIcon} />
                        <span className={styles.textSmall}
                            title={props.demoRequestItem?.CreatedOn}>{DateUtils.getFormattedDate(props.demoRequestItem.CreatedOn)}</span>
                    </div>
                </div>
                <div className={styles.demoRequestLabel}>
                    <span>Status</span>
                    <div className={styles.iconContentContainer}>
                        <StatusIcon status={props.demoRequestItem.Status} statusDescription={props.demoRequestItem.OperationStatus} />
                        <span className={`${styles.textSmall} ${styles.turncateText}`}
                            title={props.demoRequestItem.OperationStatus}>{props.demoRequestItem.OperationStatus}</span>
                    </div>
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <PrimaryButton
                    id={`btnDemoScripts-${props.demoRequestItem.TemplateName}`}
                    text={messages.btnDemoScriptsText}
                    aria-label={messages.ariaLabelBtnDemoScript}
                    disabled={!props.demoRequestItem.isDemoScriptsEnabled}
                    allowDisabledFocus
                    {...Nsat.EventTriggerAttribute}
                    onClick={() => {
                        props.onDemoScriptClick(props.demoRequestItem)
                    }}
                />
                <DefaultButton
                    id={`btnConfigSteps-${props.demoRequestItem.TemplateName}`}
                    text={messages.btnConfigStepsText}
                    aria-label={messages.ariaLabelBtnConfigSteps}
                    disabled={!props.demoRequestItem.isConfigurationStepsEnabled}
                    allowDisabledFocus
                    {...Nsat.EventTriggerAttribute}
                    onClick={() => {
                        props.onDemoAssetClick(props.demoRequestItem)
                    }}
                />
                <DefaultButton
                    id={`btnDemoAssets-${props.demoRequestItem.TemplateName}`}
                    text={messages.btnDemoAssetsText}
                    aria-label={messages.ariaLabelBtnDemoAssets}
                    allowDisabledFocus
                    {...Nsat.EventTriggerAttribute}
                    onClick={() => {
                        props.onDemoAssetClick(props.demoRequestItem)
                    }}
                />
            </div>
        </div>
    </div>
}

const getCardStylingByDemoTypeClassName = (demoType: number | undefined): string => {
    if (demoType === DemoType.shareddemos) {
        return styles.cardSharedDemoType;
    } else if (demoType === DemoType.clickthroughs) {
        return styles.cardClickthroughDemoType;
    }
    return styles.cardDefaultDemoType;
}