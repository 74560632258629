import {
    MessageBar,
} from '@fluentui/react';
import {
    Pagination,
} from 'Shared/Controls';
import { DemoRequest } from 'Services';
import { DataList } from './DataList';
import {
    messages,
    pageSize
} from '../../demorequests.settings';
import styles from '../demorequests.module.scss';

interface DemoRequestsListProps {
    totalCount: number;
    currentPage: number;
    setCurrentPage: any;
    goToLinkhandler: (demoRequestItem: DemoRequest) => void;
    demoScriptsLinkHandler: (demoRequestItem: DemoRequest) => void;
    configStepsLinkHandler: (demoRequestItem: DemoRequest) => void;
    currentPageData?: DemoRequest[];
    isEnvironmentLinkActive: (demoRequestItem: DemoRequest) => boolean;
    demoType?: number;
}

export const DemoRequestsList = (props: DemoRequestsListProps) => {
    return (
        <div key="demo-request-list" id="demos-list-container">
            {props.currentPageData && props.currentPageData.length === 0 &&
                <div className={styles.infoBox} key="no-data-found">
                    <MessageBar
                        delayedRender={false}
                        role="none"
                    >
                        {messages.noRecordsFound}
                    </MessageBar>
                </div>}

            {props.currentPageData && props.currentPageData.map((demoRequestItem: DemoRequest) => {
                return <DataList
                    demoRequestItem={demoRequestItem}
                    onDemoAssetClick={props.goToLinkhandler}
                    onDemoScriptClick={props.demoScriptsLinkHandler}
                    onConfigStepsClick={props.configStepsLinkHandler}
                    isEnvironmentLinkActive={props.isEnvironmentLinkActive}
                    demoType={props.demoType}
                    key={demoRequestItem.DemoRequestId}
                />
            })}

            <Pagination
                className="pagination-bar"
                currentPage={props.currentPage}
                totalCount={props.totalCount}
                pageSize={pageSize}
                onPageChange={page => props.setCurrentPage(page)}
            />
        </div>
    )
}
