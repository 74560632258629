import { useBoolean } from '@fluentui/react-hooks';
import { ChatPanel } from 'Pages/Chat/components/ChatPanel/ChatPanel';
import { useGlobalContext } from 'Providers/ContextProvider';
import { RoleManager } from 'Shared/helpers/role-manager';
import { links } from 'Shared/links';
import { DateUtils } from 'Shared/utils/date-utils';
import { renderNsatSurveyWithoutEligibilityCheck } from 'Services/nsatSvc/nsatSvcClient';
import { FeatureFlagName } from '../../constants';
import styles from './footer.module.scss';

const strings = {
    demohubSupport: 'BizApps Demo Support.',
    copyRightText: 'Microsoft',
    contactUsText: 'If you have any questions, please contact',
    dataPrivacyNotice: 'Data Privacy Notice',
    termsService: 'Terms of service',
    rateExperience: 'Rate your experience'
}

export const Footer = () => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const { roles, featureFlags } = useGlobalContext();
    const isDemoHubUser = RoleManager.isDemoHubUser(roles);
    const isDemoHubSeller = RoleManager.isDemoHubSeller(roles);
    const isCopilotFeatureEnabled = featureFlags?.includes(FeatureFlagName.Copilot);

    const handleRateExperienceClick = () => {        
        renderNsatSurveyWithoutEligibilityCheck(false);
    };

    return (
        <div className={styles.footer}>
            <div className={styles.footerWrapper}>
                <hr role="presentation" />
                <div className={styles.footerItems}>
                    {
                        isDemoHubUser &&
                        <div>
                            <span>{strings.contactUsText} <a id='lnkFooterContactSupport'
                                href={links.contactSupport}
                                    className={styles.link}>{strings.demohubSupport}</a></span>
                                <span className={styles.separator}>|</span>
                                <a href="#" onClick={handleRateExperienceClick} className={styles.link}><span>{strings.rateExperience}</span></a>
                        </div>
                    }

                    <div className={styles.footerItems}>
                        <a href={links.dataPrivacyNotice} target="_blank" id='lnkDataPrivacyNotice'><span>{strings.dataPrivacyNotice}</span></a>
                        <a href={links.termsService} target="_blank" id='lnkTermsService'><span>{strings.termsService}</span></a>
                    </div>

                    <div>
                        <span>&copy; {DateUtils.getYearName()} {strings.copyRightText}</span>
                    </div>
                    {
                        (isDemoHubSeller && isCopilotFeatureEnabled && (5 > 10)) &&
                        <div>
                            <button className={styles.floatingChatIcon} onClick={openPanel} id='btnChatbot'>
                                <img src="/images/chatcopilot/ChatBot.png" alt='chatCopilot' />
                            </button>
                            <ChatPanel isOpen={isOpen} dismissPanel={dismissPanel} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}