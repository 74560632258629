const urls = {
    fno: 'https://aka.ms/demohubfnotemplate',
    fsi: 'https://aka.ms/demohubfsitemplate',
    retail: 'https://aka.ms/demohubretailtemplate',
    faq: 'https://aka.ms/demohubfaq',
    sendFeedback: 'https://aka.ms/demohubsellerfeedback',
    icmLink: 'http://aka.ms/demohubsupport',
    contactSupport: 'mailto:BizAppsDemoSupport@microsoft.com',
    privacyCookies: 'https://go.microsoft.com/fwlink/?LinkId=248681',
    termsService: 'https://go.microsoft.com/fwlink/?LinkId=276853',
    previewTermsService: 'https://go.microsoft.com/fwlink/?linkid=2235796',
    copilotFeedbackForm: 'https://aka.ms/demohubcopilotfeedback',
    dataPrivacyNotice: 'https://go.microsoft.com/fwlink/?LinkId=518021',
    surveyLink: 'https://aka.ms/demohubsurvey',
    newsLetterLink: 'https://aka.ms/demohubnewsletter',
    cdxTenantLink: 'https://aka.ms/demohubazuresub',
    extendDemoEnvironment: 'https://apps.preview.powerapps.com/play/e/839eace6-59ab-4243-97ec-a5b8fcc104e4/a/3af70aa2-e27b-4059-a7a5-b39cc3c11169',
    requestDemoEnvironment: 'mailto:BizAppsDemoSupport@microsoft.com?subject=Request%20a%20new%2012-month%20demo%20environment',
    addAzSubscription: 'https://aka.ms/AzAddSub'
}

export const links = {
    fno: urls.fno,
    fsi: urls.fsi,
    retail: urls.retail,
    faq: urls.faq,
    sendFeedback: urls.sendFeedback,
    icmLink: urls.icmLink,
    contactSupport: urls.contactSupport,
    privacyCookies: urls.privacyCookies,
    termsService: urls.termsService,
    previewTermsService: urls.previewTermsService,
    copilotFeedbackForm: urls.copilotFeedbackForm,
    dataPrivacyNotice: urls.dataPrivacyNotice,
    surveyLink: urls.surveyLink,
    newsLetterLink: urls.newsLetterLink,
    cdxTenantLink: urls.cdxTenantLink,
    additionalResources: {
        extendDemoEnvironment: urls.extendDemoEnvironment,
        requestDemoEnvironment: urls.requestDemoEnvironment,
        addAzSubscription: urls.addAzSubscription
    }
}