import { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
    SpinnerSize
} from '@fluentui/react';
import {
    Loader
} from 'Shared/Controls';
import { CommonUtils, DomElementEventListeners } from 'Shared/Utils';
import { DemoType } from 'Services/Enums';
import { useMyRequests } from '../hooks/useDemoRequests';
import {
    requestFilters,
    messages,
    pageSize
} from '../demorequests.settings';
import { DemoRequestsList } from './data-list/DemoRequestsList';
import { RefreshDemoEnvironments } from './refresh-demo-environments/RefreshDemoEnvironments';

import styles from './demorequests.module.scss';

export const DemoList = () => {
    const { demotype } = useParams<{ demotype: string }>();
    requestFilters.DemoType = demotype ? DemoType[demotype as keyof typeof DemoType] : DemoType.mydemos;
    const {
        Loading,
        DemoRequests,
        GoToLinkHandler,
        goToDemoScriptsLink,
        goToConfigStepsLink,
        currentPage,
        setCurrentPage,
        error,
        isEnvironmentLinkActive,
        loadDemos
    } = useMyRequests(requestFilters);

    const currentPageData = useMemo(() => {
        if (currentPage > 0) {
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            return DemoRequests.slice(firstPageIndex, lastPageIndex);
        }
    }, [currentPage, DemoRequests]);

    useEffect(() => {
        if (!Loading) {
            DomElementEventListeners.addNsatEventListeners('#demos-list-container');
        }
    }, [Loading, currentPage])

    if (CommonUtils.redirectToErrorPage(error)) {
        return <Navigate to="/error" state={error} />
    }

    return (
        <div className={styles.demoRequests}>
            {!Loading ?
                <>
                    {requestFilters.DemoType === DemoType.mydemos && DemoRequests?.length > 0 &&
                        <RefreshDemoEnvironments OnSuccessfullRefresh={loadDemos} />
                    }
                    <DemoRequestsList totalCount={DemoRequests.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        goToLinkhandler={GoToLinkHandler}
                        demoScriptsLinkHandler={goToDemoScriptsLink}
                        configStepsLinkHandler={goToConfigStepsLink}
                        currentPageData={currentPageData}
                        isEnvironmentLinkActive={isEnvironmentLinkActive}
                        demoType={requestFilters.DemoType}
                    />
                </>
                : <Loader
                    Message={messages.loadingDemos}
                    Size={SpinnerSize.large}
                />
            }
        </div>
    )
}